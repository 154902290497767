



















































































































































































































































import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import {
  APP_ENG_ASSESSMENT_COMPLETED,
  ASSESSMENT_QUESTIONNAIRES_ENGLISH,
  ASSESSMENT_QUESTIONNAIRES_ENGLISH_UPDATE,
  SUBMIT_APP_ASSESSMENT_ANSWERS
} from "@/store/modules/candidates/constants";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ErrorPage from "@/views/ErrorPage.vue";
import { convert_seconds_to_minutes } from "@/utils/global";
import AppTimer from "@/components/AppTimer.vue";
import {
  SubmitAssessmentAnswersApiPayload,
  UpdateAssessmentQuestionPayload
} from "@/store/modules/candidates/interfaces";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import AppEngWrittenAssessmentLoader from "./AppEngWrittenAssessmentLoader.vue";
import AppEngAssessmentHeader from "./AppEngAssessmentHeader.vue";

export default Vue.extend({
  name: "AppEngWrittenAssessment",
  components: {
    ErrorPage,
    AppTimer,
    AppEngWrittenAssessmentLoader,
    AppEngAssessmentHeader
  },
  data() {
    return {
      current_question_index: 0, // Current question index
      current_question: null as null | AppAssessments.AssessmentQuestionnaires, // Current question
      disabled_next_question: true, // Disable next question button
      current_question_answer: "", // Current question answer
      answer_words_count: 0, // Current question answer words count
      submit_ans_loading: false, // Submit answer loading
      answer_editor_options: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"]
          ]
        },
        placeholder: "Type answer here",
        table: [] // Answer editor options
      }
    };
  },
  computed: {
    ...mapGetters("candidate", {
      app_eng_assessments: ASSESSMENT_QUESTIONNAIRES_ENGLISH
    }),
    AppAssessments() {
      return AppAssessments;
    },
    quill() {
      const quill = this.$refs?.answer_editor as any;
      if (quill && quill.quill) return quill.quill;
      else return null;
    }
  },
  watch: {
    // Watch current question answer and enable next button if answer editor exist
    // and answer editor text length is greater than 0 and less than max words allowed
    // for current question otherwise disable next button
    current_question_answer: {
      handler() {
        const ref = this.$refs.answer_editor as any; // Answer editor ref
        // If answer editor not exist then disable next button
        if (!ref) this.disabled_next_question = true;
        // If answer editor not exist then disable next button
        else {
          const text = ref.quill.getText(); // Get answer editor text
          const words = text.split(" "); // Split text into words
          // Get min and max words allowed for current question
          const min_words =
            this.current_question?.question_options?.allowed_characters[0] || 1;
          const max_words =
            this.current_question?.question_options?.allowed_characters[1] ||
            min_words;
          this.answer_words_count = words.length; // Set answer words count
          // If words length is greater than 0
          // and words length is between min and max words allowed then enable next button
          if (words.length >= min_words && words.length <= max_words) {
            this.disabled_next_question = false; // Enable next button
          } else this.disabled_next_question = true; // Disable next button otherwise
        }
      },
      immediate: true
    }
  },
  mounted() {
    // Set current question
    this.current_question =
      this.app_eng_assessments.written[this.current_question_index];
    if (this.quill) {
      this.quill.root.addEventListener("copy", (e: ClipboardEvent) => {
        e.preventDefault();
      });
      this.quill.root.addEventListener("cut", (e: ClipboardEvent) => {
        e.preventDefault();
      });
      this.quill.root.addEventListener("paste", (e: ClipboardEvent) => {
        e.preventDefault();
      });
    }
  },
  methods: {
    ...mapMutations("candidate", {
      update_app_eng_assessment_questions:
        ASSESSMENT_QUESTIONNAIRES_ENGLISH_UPDATE,
      set_app_eng_assessments: ASSESSMENT_QUESTIONNAIRES_ENGLISH,
      update_assessment_completed: APP_ENG_ASSESSMENT_COMPLETED
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    ...mapActions("candidate", {
      submit_assessment_answers: SUBMIT_APP_ASSESSMENT_ANSWERS
    }),
    convert_seconds_to_minutes,
    // Process next question and set current question to next question if exist
    async process_next_question() {
      this.submit_ans_loading = true; // Set submit answer loading
      if (!this.current_question) return; // If current question not exist then return
      // Update current question answer in store
      const payload: UpdateAssessmentQuestionPayload = {
        filter: this.app_eng_assessments.filter,
        answer: this.current_question_answer,
        question_id: this.current_question.id
      };
      this.update_app_eng_assessment_questions(payload); // Update current question answer in store
      // Api Call to save current question answer
      const api_payload: SubmitAssessmentAnswersApiPayload = {
        answer: this.current_question_answer,
        question_id: this.current_question.id,
        answer_type: this.current_question.question_type,
        assessment_id: this.current_question.assessment_id
      };
      // Set done to true if current question index is last question index
      if (
        this.current_question_index ===
        this.app_eng_assessments.written.length - 1
      ) {
        api_payload.done = true;
      }
      const resposne = await this.submit_assessment_answers(api_payload);
      // If response not exist then set root error and return
      if (!resposne) {
        this.set_root_error(
          "Something went wrong while submitting assessment answers"
        );
        return;
      }
      // If current question index is less than total questions length then set current question to next question
      if (
        this.current_question_index <
        this.app_eng_assessments.written.length - 1
      ) {
        this.current_question_index++; // Increment current question index
        this.current_question_answer = ""; // Reset current question answer
        this.answer_words_count = 0; // Reset current question answer words count
        this.current_question =
          this.app_eng_assessments.written[this.current_question_index]; // Set current question to next question
      } else {
        // If current question index is last question index then set assessment completed to true
        this.set_app_eng_assessments({
          speaking: this.app_eng_assessments.speaking,
          speaking_completed: this.app_eng_assessments.speaking_completed,
          written: this.app_eng_assessments.written,
          filter: this.app_eng_assessments.filter,
          cancelled: false,
          completed: true,
          current_question_index: this.current_question_index
        });
        this.$emit("assessment_completed"); // Emit assessment completed event
      }
      this.submit_ans_loading = false; // Set submit answer loading
    },
    get_desired_color(index: number) {
      switch (index) {
        case 0:
          return "green";
        case 1:
          return "yellow";
        case 2:
          return "danger";
        default:
          return "secondary";
      }
    },
    event_cancellation(event: any) {
      event.preventDefault();
    }
  }
});
